body {
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
  background-color: #000;
}

.site-header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  padding: 0 20px; /* Padding for the gap */
  box-sizing: border-box; /* Include padding in width calculation */
}

.site-header::after {
  content: '';
  display: block;
  width: calc(100% - 40px); /* Width minus the padding */
  height: 1px;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 0 auto; /* Center the line */
}

.container {
  display: flex;
  justify-content: space-between;
  color: white;
}

.site-header .container {
  max-width: 1200px; /* Limit the container width if desired */
  margin: 0 auto; /* Center the container */
  padding: 1rem 0; /* Add padding to the container */
}

.site-header nav {
  display: flex;
  justify-content: flex-end;
}

.site-header ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 2rem;
}

.site-header li {
  margin: 0;
}

.site-header a {
  color: white;
  text-decoration: none;
  font-family: 'Raleway', sans-serif;
  font-weight: 900;
}

.site-header a:hover {
  text-decoration: underline;
}

.hero {
  background-color: #341700; /* Dark green background */
  position: relative;
  height: 100vh;
  overflow: hidden;
  font-family: "Montserrat", sans-serif;
}

.cube {
  position: absolute;
  top: 80vh;
  left: 45vw;
  width: 10px;
  height: 10px;
  border: solid 1px #662e00; /* Dark green border */
  transform-origin: top left;
  transform: scale(0) rotate(0deg) translate(-50%, -50%);
  animation: cube 12s ease-in forwards infinite;
}
.cube:nth-child(2n) {
  border-color: #995700; /* Lighter green border */
}
.cube:nth-child(2) {
  animation-delay: 2s;
  left: 25vw;
  top: 40vh;
}
.cube:nth-child(3) {
  animation-delay: 4s;
  left: 75vw;
  top: 50vh;
}
.cube:nth-child(4) {
  animation-delay: 6s;
  left: 90vw;
  top: 10vh;
}
.cube:nth-child(5) {
  animation-delay: 8s;
  left: 10vw;
  top: 85vh;
}
.cube:nth-child(6) {
  animation-delay: 10s;
  left: 50vw;
  top: 10vh;
}
.cube:nth-child(7) {
  animation-delay: 11s;
  left: 63vw;
  top: 20vh;
}
.cube:nth-child(8) {
  animation-delay: 12s;
  left: 35vw;
  top: 62vh;
}

@keyframes cube {
  from {
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    opacity: 1;
  }
  to {
    transform: scale(20) rotate(960deg) translate(-50%, -50%);
    opacity: 0;
  }
}

.text-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 200%;
  color: white;
  text-align: center;
  z-index: 1;
}

.text-container h1 {
  margin: 0;
  font-size: 200%;
}

.text-container .PianoNic {
  font-size: 100%;
  color: rgba(225, 225, 225, .01);
  background-image: url("./images/cocoa.jpg");
  background-repeat: repeat;
  -webkit-background-clip: text;
  background-clip: text;
  animation: animate 50s ease-in-out infinite;
  text-transform: uppercase;
  font-weight: 900;
}

@keyframes animate {
  0%, 100% {
    background-position: left top;
  }
  25% {
    background-position: right bottom;
  }
  50% {
    background-position: left bottom;
  }
  75% {
    background-position: right top;
  }
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}
